@charset "UTF-8";

// sass-lint:disable brace-style
// ----------------------------------------------
.bg-gray {
  background-color: #ccc !important;
}

.bg-red {
  background-color: #f00 !important;
}

.bg-blue {
  background-color: #0051c3 !important;
}

.bg-dodger-blue {
  background-color: #7ec3ec !important;
}

.bg-green-yellow {
  background-color: #a6d171 !important;
}

.bg-soft-red {
  background-color: #f49980 !important;
}

.bg-orange {
  background-color: #f4bd50 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-cyan {
  background-color: #54c7c7 !important;
}

.bg-strong-cyan {
  background-color: #25b2d5 !important;
}

.bg-pink {
  background-color: #9a5699 !important;
}

// ----------------------------------------------
.width-15p {
  width: 15% !important;
}

.width-16p {
  width: 16% !important;
}

.width-18p {
  width: 18% !important;
}

.width-20p {
  width: 20% !important;
}

.width-30p {
  width: 30% !important;
}

.width-40p {
  width: 40% !important;
}

.width-50p {
  width: 50% !important;
}

// ----------------------------------------------
.width-150 {
  width: 150px !important;
}

// ----------------------------------------------
.max-width-800 {
  max-width: 800px !important;
}

.max-width-1280 {
  max-width: 1280px !important;
}

// sass-lint:enable brace-style

// ----------------------------------------------
.clear {
  clear: both !important;
}

// ----------------------------------------------
.v-top {
  vertical-align: top !important;
}

.v-middle {
  vertical-align: middle !important;
}

.v-bottom {
  vertical-align: bottom !important;
}

// ----------------------------------------------
.box-shadow-yellow {
  box-shadow: 0 0 30px 15px rgba(255, 255, 0, 100%); // offset-x | offset-y | blur-radius | spread-radius | color
}

.box-shadow-yellow-spread-sm {
  box-shadow: 0 0 30px 0 rgba(255, 255, 0, 100%);
}

.box-shadow-yellow-spread-lg {
  box-shadow: 0 0 30px 20px rgba(255, 255, 0, 100%);
}

// ----------------------------------------------
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
