@charset "UTF-8";

// ----------------------------------------------
.section-fv {
  padding-top: 43px;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }

  .container {
    max-width: 1180px;
  }

  .video-fv {
    width: 100%;
  }

  .badge-fv {
    display: block;
    padding: 8px 10px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: .04em;
    text-align: center;
    background-color: #54c7c7;
    border-radius: 0;
    white-space: normal;

    @include media-breakpoint-down(md) {
      font-size: 15px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .d-flex-fv {
    flex-direction: row-reverse;
    justify-content: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .flex-right {
      @include media-breakpoint-up(md) {
        padding-right: 22px;
      }

      @include media-breakpoint-down(xl) {
        max-width: 615px;
      }

      @include media-breakpoint-down(lg) {
        max-width: 450px;
      }

      @include media-breakpoint-down(md) {
        max-width: 350px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .flex-left {
      @include media-breakpoint-up(md) {
        width: 40%;
        padding-top: 27px;
      }

      @include media-breakpoint-up(lg) {
        width: 46.3%;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        margin-bottom: 15px;
      }

      img {
        width: 100%;

        @include media-breakpoint-down(md) {
          max-width: 250px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  .title-fv {
    display: inline-block;
    margin-top: 15px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: .04em;

    @include media-breakpoint-down(xl) {
      font-size: 34px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 29px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 25px;
      font-size: 24px;
    }

    small {
      display: block;
      font-size: 28px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: .001em;

      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }

      @include media-breakpoint-down(xl) {
        font-size: 25px;
      }

      @include media-breakpoint-down(lg) {
        font-size: 21px;
      }

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }

    span {
      display: block;
      margin-bottom: 9px;
      color: #274b81;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.6;
      text-align: center;
      letter-spacing: .04em;

      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 5px;
      }
    }
  }

  .list-fv {
    margin: 31px 0 40px 10px;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
      margin-bottom: 30px;
      margin-left: 0;
    }

    li {
      position: relative;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.6;
      padding-left: 33px;
      letter-spacing: .04em;

      @include media-breakpoint-down(lg) {
        font-size: 22px;
      }

      @include media-breakpoint-down(md) {
        font-size: 20px;
        padding-left: 28px;
        line-height: 1.4;
      }

      + li {
        margin-top: 13px;

        @include media-breakpoint-down(md) {
          margin-top: 10px;
        }
      }

      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        width: 26px;
        height: 23px;
        background: url("../img/icon/circle-tick.svg") 50% 50%/cover no-repeat;
        content: "";

        @include media-breakpoint-down(md) {
          top: 6px;
          width: 20px;
          height: 18px;
        }
      }

      span {
        padding-bottom: 3px;
        color: #ab3838;
        background: linear-gradient(transparent 65%, #a8e6e6 65%);
      }
    }
  }
}

// ----------------------------------------------
.section-contact-banner {
  padding-top: 40px;
  padding-bottom: 40px;

  &,
  a {
    color: #fff;
  }

  .banner {
    padding: 60px 30px;
    background-color: #54c7c7;

    @include media-breakpoint-down(md) {
      padding: 40px 25px;
    }

    .title-banner {
      font-size: 25px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    a {
      font-weight: bold;
      background-color: #fff;
      border-radius: 40px;
      transition: .3s all ease;

      &:hover {
        background-color: #089c9c;
        transition: .3s all ease;

        &,
        span {
          color: #fff !important;
        }
      }
    }
  }
}

//----------------------------------------------
.container-stack {
  color: #fff !important;
}

//----------------------------------------------
.section-qa {
  padding-top: 60px;
  padding-bottom: 60px;
}

//----------------------------------------------
.card-collapse {
  $card-collapse-btn-color: #424242;
  margin-bottom: 0;
  background-color: #fff;
  border: 0;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 10%);

  + .card-collapse {
    margin-top: 25px;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
    }
  }

  .card-body {
    padding: 0;
  }

  .card-title {
    padding: 0;
    margin-bottom: 0;
    background-color: #fff;
    border-radius: 15px;

    a {
      position: relative;
      display: block;
      padding: 25px 70px 18px 63px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      color: $card-collapse-btn-color;
      text-decoration: none;

      @include media-breakpoint-down(md) {
        padding: 10px 45px 10px 35px;
        font-size: 14px;
      }

      &:before {
        position: absolute;
        top: 21px;
        left: 32px;
        font-size: 20px;
        font-weight: bold;
        background: -webkit-linear-gradient(left, #f97409, #f94d09);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        content: "Q.";

        @include media-breakpoint-down(md) {
          top: 9px;
          font-size: 16px;
          left: 10px;
        }
      }

      &:hover,
      &:focus,
      &[aria-expanded="true"] {
        text-decoration: none;
      }

      &:hover {
        opacity: .8;
      }

      &[aria-expanded="true"] {
        .icon {
          .bar {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .card-text-detail {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    padding: 17px 4px 23px 38px;
    margin: 0 32px;
    border-top: 1px solid #e6e6e6;

    @include media-breakpoint-down(md) {
      padding: 12px 6px 12px 15px;
      font-size: 14px;
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .icon {
    position: absolute;
    top: 3px;
    right: 33px;
    bottom: 0;
    display: block;
    width: 22px;
    height: 22px;
    margin-top: auto;
    margin-bottom: auto;
    background: transparent;
    content: "";
    transition: 300ms all ease;

    @include media-breakpoint-down(md) {
      right: 10px;
    }
  }

  .bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 2px;
    height: 14px;
    margin: auto;
    background: linear-gradient(135deg, #f97509 0%, #f93809 100%);
    content: "";
    transition: 300ms all ease;

    @include media-breakpoint-down(md) {
      height: 10px;
    }

    &:nth-child(2n) {
      transform: rotate(90deg);
    }
  }
}
