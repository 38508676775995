@import "variables";
@import "../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components
/*! purgecss start ignore */
//@import "../node_modules/bootstrap/scss/root";
/*! purgecss end ignore */

//@import "../node_modules/bootstrap/scss/reboot";
//@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
//@import "../node_modules/bootstrap/scss/tables";
//@import "../node_modules/bootstrap/scss/forms";
//@import "../node_modules/bootstrap/scss/buttons";

/*! purgecss start ignore */
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
/*! purgecss end ignore */

// @import "../node_modules/bootstrap/scss/button-group";
//@import "../node_modules/bootstrap/scss/nav";
//@import "../node_modules/bootstrap/scss/navbar";
//@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
//@import "../node_modules/bootstrap/scss/breadcrumb";
// @import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
// @import "../node_modules/bootstrap/scss/alert";
// @import "../node_modules/bootstrap/scss/progress";
//@import "../node_modules/bootstrap/scss/list-group";
//@import "../node_modules/bootstrap/scss/close";
// @import "../node_modules/bootstrap/scss/toasts";

/*! purgecss start ignore */
//@import "../node_modules/bootstrap/scss/modal";
// @import "../node_modules/bootstrap/scss/tooltip";
// @import "../node_modules/bootstrap/scss/popover";
// @import "../node_modules/bootstrap/scss/carousel";
// @import "../node_modules/bootstrap/scss/spinners";
// @import "../node_modules/bootstrap/scss/offcanvas";
// @import "../node_modules/bootstrap/scss/placeholders";
/*! purgecss end ignore */

// Helpers
//@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// ----------------------------------------------
//@import "colors";

// ----------------------------------------------
// Load & overwrite external libraries, plugins.
// @import "node_modules/sal.js/src/sal";
// @import "sal";

/*! purgecss start ignore */
//@import "node_modules/swiper/swiper";
//@import "node_modules/swiper/components/scrollbar/scrollbar";
//@import "node_modules/swiper/components/pagination/pagination";
//@import "node_modules/swiper/components/navigation/navigation";
//@import "node_modules/swiper/components/effect-fade/effect-fade";
//@import "swiper";

// ----------------------------------------------
@import "node_modules/aos/src/sass/aos";

// ----------------------------------------------
// Overwrite Twitter Bootstrap Core SCSS
//@import "scaffolding";
//@import "type";
//@import "grid";
//@import "tables";
//@import "forms";
//@import "buttons";

// ----------------------------------------------
// Overwrite Twitter Bootstrap Components
//@import "navs";
//@import "breadcrumbs";
//@import "pagination";
//@import "media";
//@import "list-group";
//@import "panels";
//@import "modal";
@import "utilities";
//@import "responsive-utilities";
//@import "badge";
//@import "card";

// ----------------------------------------------
// Add Original Components
//@import "wordpress";
//@import "title";
//@import "frame";

// ----------------------------------------------
@import "section";
//@import "components";
/*! purgecss end ignore */
